<template>
  <div class="layout-create">
    <HeaderDesktopDefault />
    <main class="main layout-create__main">
      <slot />
    </main>
    <footer class="footer layout-create__footer"></footer>
  </div>
  <BaseButtonIconArrowBack @click="$router.back()" class="link-back" />
</template>

<script>
import HeaderDesktopDefault from "@/components/Header/HeaderDesktopDefault.vue";
import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";

export default {
  components: { HeaderDesktopDefault, BaseButtonIconArrowBack },
};
</script>

<style scoped>
.layout-create {
  min-height: 100vh;
  display: flex;
  flex-flow: column;
}
.layout-create__main {
  width: var(--layout-default--width);
  flex-grow: 1;
  margin: 0 auto;
  padding: var(--layout-default--padding);

  /* todo this shit */
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.link-back {
  position: fixed;
  top: calc(100vh / 2 - 36px);
  left: calc((100vw - var(--layout-view-wrapper--width)) / 4 - 36px / 2);
}
</style>
